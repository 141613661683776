<template>
    <div class="hero login-hero is-fullheight is-app-grey">
        <div style="display:contents; margin: auto">
          <img src="images/stratsbuilder.png" alt="Banner"/>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../scss/abstracts/_variables.scss';

.dragmove {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.flex-table {
  .flex-table-header {
    display: flex;
  }

  .flex-list-inner {
    max-height: 50vh;
    overflow-y: scroll;

    .flex-table-item {
      div {
        color: $light;
      }
    }
  }
}


</style>
<script setup lang="ts">
</script>
